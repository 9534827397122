import React from "react";
import SignInUpForm from "../../../../components/forms/SignInUp/SignInUpForm";
import { userRoles } from "../../../../lib/constants";
import Seo from "../../../../components/Seo";
import globhePng from "../../../../assets/logos/globhe_logo/Globhe.png";

const PilotSignUp = () => {
  return (
    <>
      <Seo
        title={"Drone Pilot Login | Access Your Globhe Dashboard"}
        image={globhePng}
        description={`Welcome back, operator! Log in to manage your missions, 
        track your earnings, and discover new opportunities. Your next mission awaits!`} //image={blogsImage}
      />
      <SignInUpForm formType="login" userType={userRoles.pilot} />
    </>
  );
};

export default PilotSignUp;
